<template>
    <div>
        <div class="main-title">优惠券</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div class="list-head" >
                    <p class="list-head-title">温馨提示</p>
                    <ul>
                        <li >用于购买内容商品时享受优惠折扣，学员购买商品时先领取优惠券，付费时使用优惠券抵扣部分费用</li>
                        <li >可指定购买全店任意商品时使用，或购买指定商品时使用</li>
                        <li >店铺优惠券一经发行，后续编辑时只可以修改发行量</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-select v-model="value" clearable placeholder="选择分类" size="small">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-select v-model="value" clearable placeholder="选择分类" size="small">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/discount/add" class="router-link" >
                        <el-button type="primary" size="small" >新建优惠券</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>

                    <el-table-column
                            property="name"
                            label="优惠券名称"
                            >
                    </el-table-column>

                    <el-table-column
                            property="name"
                            label="总量/领取数"
                            >
                        <template slot-scope="scope">
                            <span>{{scope.row.count}}/{{scope.row.get_count}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            property="amount"
                            label="优惠金额"
                            >
                    </el-table-column>

                    <el-table-column
                            label="有效期"
                            >
                        <template slot-scope="scope">
                            <span>{{scope.row.start_time}} 至 <br /> {{scope.row.end_time}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="类型"
                            width="200">
                        <template slot-scope="scope">
                            <span>{{scope.row.type === 1 ? '全场通用' : '指定商品可用'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-if="scope.row.status == 0" class="state-down"><i></i>已下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                  <el-popover
                                          placement="top"
                                          width="200"
                                          trigger="click">
                                      <div style="height:200px" v-loading="qrCodeLoading">
                                          <el-image :src="qrCodeUrl" >
                                              <div slot="placeholder" class="image-slot">
                                                加载中<span class="dot">...</span>
                                              </div>
                                            </el-image>
                                      </div>
                                      <el-link  slot="reference" type="primary" @click="shareQrCode(scope.row)">分享</el-link>
                              </el-popover>
                            </span> -
                            <span>
                                <el-link v-if="scope.row.status == 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status == 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span> -
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "template-list",
        data(){
            return {
                page:1,
                pageSize:10,
                total:0,
                loading:true,
                qrCodeLoading:true,
                qrCodeUrl:'',
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                currentRow: null
            }
        },
        methods:{
            ...mapActions('marketing',['discountList','setDiscountStatus']),
            ...mapActions('common',['makeQrCode','delete']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            shareQrCode(row){
                let data = {
                    type:'discount',
                    id:row.id,
                }
                this.qrCodeUrl = ''
                this.qrCodeLoading = true
                this.makeQrCode(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.qrCodeUrl = res.data.url
                        this.qrCodeLoading = false
                    }
                })
            },
            getList(){
                this.discountList().then(res => {
                    console.log(res)
                    if(res.ret === 0)
                    {
                        this.total =  res.data.total
                        this.tableData = res.data.list
                        this.loading = false
                    }
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            setStatus(row, rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setDiscountStatus(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(row, rowIndex){
                let _this = this
                this.loading = true
                this.delete({id:row.id,model:"Discount"}).then(res => {
                    this.getList()
                    if(res.ret  == 0){
                        this.$message.success('删除成功')
                    }

                })
            },
        },
        mounted() {
            this.getList();
        }
    }
</script>

<style>

</style>
